// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import HoverTooltip from '../HoverTooltip/HoverTooltip';
import styles from './IconButton.module.scss';
import { useStores } from '../../../hooks/use-stores';

function Button({
  tooltipPosition = 'top',
  tooltipText,
  icon,
  onClick,
  label,
  isActive,
  style,
}) {
  const { app } = useStores();

  const fullStyle = style || {
    color: app.workspaceBarColor,
  };

  const inner = (
    <span
      className={classnames(styles.IconButton, { [styles.active]: isActive })}
      onClick={onClick}
      style={fullStyle}
    >
      {typeof icon === 'string' ? <i className={icon} /> : icon}
      {label && <span style={fullStyle}>{label}</span>}
    </span>
  );

  if (!tooltipText) {
    return inner;
  }

  return (
    <HoverTooltip
      placement={tooltipPosition}
      overlay={tooltipText}
      overlayClassName="rc-tooltip-information"
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
    >
      {inner}
    </HoverTooltip>
  );
}

export default observer(Button);
