// @ts-nocheck

import uuid from "uuid";
import { observable, action, computed } from "mobx";

import config from "../config";
import getSpiralTilePosition from "../../utils/spiralTile";
import * as api from "../../services/api";

import Word from "./word";
import { makeObservable } from "mobx";

export default class Cloud {
  id = uuid.v1();
  children = [];
  rows = [];
  words = [];
  visibleWordCount = 0;

  workspace = null;

  state = {
    cloudy: false,
    softDeleted: false,
    marked: false,
    active: false,
  };

  radius;
  position = {
    x: 15000,
    y: 15000,
  };

  constructor(word, defaultWords, defaultVisible, color, uri) {
    this.init(word, defaultWords, defaultVisible, uri);

    this.previousColor = color;

    makeObservable(this, {
      id: observable,
      children: observable,
      visibleWordCount: observable,
      state: observable,
      radius: observable,
      position: observable,
      addWordPosition: computed,
      setWords: action,
      setState: action,
      toggleState: action,
      increaseSize: action,
      setSize: action,
      changeWord: action,
      addWord: action,
    });
  }

  init(word, defaultWords, defaultVisible, uri) {
    this.rows = [];
    this.children = [];
    this.word = word;
    this.uri = uri;

    this.visibleWordCount = Math.min(defaultVisible, defaultWords.length + 1);

    this.words = [
      new Word({ label: word, isMain: true }),
      ...defaultWords
        .map((w) => new Word(w))
        .slice(0, config.clouds.wordLimit - 2),
      new Word({ label: ".", weight: 0 }), // Add point to signal end of word array
    ];

    this.generate();
  }

  get addWordPosition() {
    const lastChild = this.children[this.children.length - 1];
    return {
      x: lastChild.position.x + lastChild.dimensions.width,
      y: lastChild.position.y,
      height: lastChild.dimensions.height,
    };
  }

  setWords(words) {
    const diff = this.words.length - words.length;
    this.words = words;
    this.visibleWordCount = Math.max(0, this.visibleWordCount - diff);
    this.generate();
  }

  setState(key, value) {
    this.state[key] = value;
  }

  toggleState(key, value = {}) {
    this.state[key] =
      JSON.stringify(this.state[key]) === JSON.stringify(value) ? false : value;
  }

  increaseSize(by = 1) {
    if (this.visibleWordCount + by <= 0) {
      return;
    }
    if (
      this.visibleWordCount + by >
      Math.min(config.clouds.wordLimit, this.words.length)
    ) {
      return;
    }

    this.visibleWordCount += by;
    this.generate();
  }

  setSize(size) {
    this.visibleWordCount = size;
    this.generate();
  }

  changeWord(word, words) {
    this.init(word, words, 15);
  }

  setBody(body) {
    this.body = body;
  }

  setPosition(x, y) {
    if (this.position.x !== x) {
      this.position.x = x;
    }
    if (this.position.y !== y) {
      this.position.y = y;
    }
  }

  syncPosition() {
    const x = this.body.position.x;
    const y = this.body.position.y;
    if (this.position.x !== x) {
      this.position.x = x;
    }
    if (this.position.y !== y) {
      this.position.y = y;
    }
  }

  addWord(label, position = -1) {
    const pos = position === -1 ? this.visibleWordCount : position;
    const word = new Word({ label });

    this.words = [...this.words.slice(0, pos), word, ...this.words.slice(pos)];
    this.increaseSize(1);
  }

  handleConfigChanged() {
    this.generate();
  }

  generate() {
    this.rows = [];
    this.children = [];

    let minimumY = 0;
    const wordCount = Math.min(this.words.length, this.visibleWordCount);

    // Distribute words over rows
    for (let i = 0; i < wordCount; i++) {
      const position = getSpiralTilePosition(i, this.word);
      const y = -position[1];
      const word = this.words[i];

      let row;
      if (word.label === ".") {
        row = this.findRowOrCreate(Math.ceil(this.rows.length / 2), word);
        word.position.x = row.length;
      } else {
        row = this.findRowOrCreate(y, word);
        word.position.x = position[0];
      }

      minimumY = Math.min(y, minimumY);
      word.position.y = y;

      row.children.push(word);
    }

    const wordHeight = config.word.font.size + config.word.padding.y * 2;
    let maximumRowWidth = 0;

    // Sort rows and words
    // Calculate word positions
    this.rows
      .sort((a, b) => a.y - b.y)
      .forEach((row) => {
        let { y } = row;
        y -= minimumY;

        let lastX = config.word.margin.x;
        row.children
          .sort((a, b) => a.position.x - b.position.x)
          .forEach((word) => {
            word.setPosition({
              x: lastX,
              y: y * wordHeight + y * config.word.margin.y,
            });
            word.setDimensions({ width: word.textWidth, height: wordHeight });

            lastX +=
              config.word.margin.x + word.textWidth + config.word.margin.x;
          });

        const rowWidth = lastX;
        maximumRowWidth = Math.max(rowWidth, maximumRowWidth);

        this.children = this.children.concat(row.children);

        row.width = rowWidth;
        row.y = y;
      });

    const height =
      this.rows.length * wordHeight +
      Math.max(0, this.rows.length - 1) * config.word.margin.y;
    const halfHeight = maximumRowWidth / 2 - height / 2;
    this.rows.forEach((row) => {
      const xOffset = (maximumRowWidth - row.width) / 2;
      row.children.forEach((word) => {
        word.setPosition({
          x: word.position.x + xOffset,
          y: word.position.y + halfHeight,
        });
      });
    });

    this.radius = maximumRowWidth / 2;
  }

  removeWord(word, visible = true) {
    if (this.workspace) {
      api.unseeWord(this.workspace.id, word.label);
    }

    const i = this.words.findIndex((w) => w.label === word);
    this.words = [...this.words.slice(0, i), ...this.words.slice(i + 1)];
    if (visible) {
      this.visibleWordCount = Math.max(0, this.visibleWordCount - 1);
      this.generate();
    }
  }

  findRowOrCreate(y, word) {
    let row = this.rows.find((r) => r.y === y);

    if (!row) {
      row = {
        y,
        children: [],
      };

      this.rows.push(row);
    }

    return row;
  }
}
