import React from "react";
import { observer } from "mobx-react";
import styles from "./AutoComplete.module.scss";

function AutoComplete(props) {
  const { predictions, handleClick, className } = props;
  return (
    <ul className={styles.predictions + " " + className}>
      {predictions.map((p, i) => {
        return (
          <li
            className={p.selected ? styles.selected : ""}
            onClick={() => handleClick(p.word)}
            key={p.word}
          >
            {p.word}
          </li>
        );
      })}
    </ul>
  );
}

export default observer(AutoComplete);
