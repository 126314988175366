import React from 'react';
import isjs from 'is_js';
import Tooltip from 'rc-tooltip';

const HoverTooltip = props => {
  if (isjs.touchDevice() || isjs.tablet()) {
    return props.children;
  }

  return <Tooltip {...props}>{props.children}</Tooltip>;
};

export default HoverTooltip;
