// @ts-nocheck
import React from 'react';
import IconButton from '../IconButton/IconButton';

const Actions = ({ activeMode, onToggleMode }) => {
  if (!activeMode || ['delete', 'mark'].indexOf(activeMode) === -1) return null;

  return (
    <span>
      <span className="h-divider" />

      {['delete'].indexOf(activeMode) > -1 && (
        <IconButton
          style={{ color: '#38b097', opacity: 1 }}
          onClick={() => {
            onToggleMode(activeMode, {
              confirmed: true,
            })();
          }}
          icon="icon-ok"
          label="confirm"
        />
      )}
      {['delete', 'mark'].indexOf(activeMode) > -1 && (
        <IconButton
          style={{ color: '#FF334B', opacity: 1 }}
          onClick={() => {
            onToggleMode(activeMode, {
              cancelled: true,
            })();
          }}
          icon="icon-not-ok"
          label="cancel"
        />
      )}
    </span>
  );
};

export default Actions;
