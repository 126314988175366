// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import Hammer from "react-hammerjs";
import classnames from "classnames";

import Space from "../Space/Space";
import Intro from "../Intro/Intro";
import Disclaimer from "../Disclaimer/Disclaimer";
import InlineInput from "../InlineInput/InlineInput";

import WorkspaceActions from "../WorkspaceActions/WorkspaceActions";
import WorkspaceModes from "../WorkspaceModes/WorkspaceModes";

import styles from "./Workspace.module.scss";
import { useStores } from "../../../hooks/use-stores";

const getEventPageLocation = (e, checkChangedTouches) => {
  if (!e.touches) {
    if (e.center) {
      return e.center;
    }

    return {
      x: e.pageX,
      y: e.pageY,
    };
  }

  if (e.touches.length !== 1 && !checkChangedTouches) {
    return false;
  }

  let touch;
  if (checkChangedTouches && e.changedTouches.length > 0) {
    touch = e.changedTouches[e.changedTouches.length - 1];
  } else {
    touch = e.touches[0];
  }

  return {
    x: touch.pageX,
    y: touch.pageY,
  };
};

function Workspace() {
  const wheelContainer = useRef(null);
  const { config, app } = useStores();
  const workspace = app.activeWorkspace;

  const [moving, setMoving] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const wheelListener = wheelContainer.current?.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();
        return false;
      },
      { passive: false }
    );
    return () => {
      document.removeEventListener("wheel", wheelListener);
    };
  }, [workspace]);

  if (app.disclaimerOpen) {
    return <Disclaimer app={app} />;
  }

  if (!workspace) {
    return <Intro />;
  }

  const style = {
    top: workspace.pan.y,
    left: workspace.pan.x,
    width: config.canvas.size,
    height: config.canvas.size,
    transform: `scale(${workspace.scale})`,
  };

  const handleDown = (e) => {
    workspace.disableInlineInput();

    const pageLocation = getEventPageLocation(e);
    if ((e.button !== 0 && !e.touches) || !pageLocation) {
      return;
    }

    workspace.receivedMouseDown(pageLocation);
  };

  const handleUp = (e) => {
    const pageLocation = getEventPageLocation(e, true);
    if (!pageLocation) {
      return;
    }

    if (
      app.activeMode.type === "give me" &&
      workspace.pan.x === workspace.lastPan.x &&
      workspace.pan.y === workspace.lastPan.y &&
      !workspace.draggedCloud
    ) {
      workspace.enableInlineInput("ADD_CLOUD_LIST", {
        position: {
          x: -workspace.pan.x + pageLocation.x,
          y: -workspace.pan.y + pageLocation.y,
        },
      });
    }

    workspace.receivedMouseUp(pageLocation);
  };

  const handleMove = (e) => {
    if (workspace.isMouseDown) {
      const pageLocation = getEventPageLocation(e);
      if (!pageLocation) {
        return;
      }
      workspace.receivedMouseMove(pageLocation);
    }
  };

  const handleWheel = (e) => {
    if (
      (e.nativeEvent.path.length === 9 || workspace.moving) &&
      !workspace.resizing
    ) {
      clearTimeout(timer);
      // // if (e.ctrlKey) {
      // //   workspace.handleZoom(e.deltaY > 0 ? workspace.scale - 0.03 : workspace.scale + 0.03);
      // //   return;
      // // }
      workspace.setMoving(true);
      workspace.handlePan({ x: e.deltaX * 2, y: e.deltaY * 2 });
      setTimer(
        setTimeout(function () {
          workspace.setMoving(false);
        }, 250)
      );
    }
  };

  const handleAddNewCloud = (e) => {
    if (e.srcEvent) {
      e.srcEvent.preventDefault();
      e.srcEvent.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();
    }

    if (app.activeMode.type) {
      return;
    }

    const pageLocation = getEventPageLocation(e);
    if (!pageLocation) {
      return;
    }

    workspace.enableInlineInput("ADD_CLOUD", {
      position: {
        x: -workspace.pan.x + pageLocation.x,
        y: -workspace.pan.y + pageLocation.y,
      },
    });
  };

  return (
    <div
      className={`${styles.wrapper} ${moving ? "moving" : ""}`}
      ref={(el) => {
        wheelContainer.current = el;
      }}
    >
      <Hammer
        onTouchStart={handleDown}
        onTouchMove={handleMove}
        onTouchEnd={handleUp}
        onDoubleTap={handleAddNewCloud}
      >
        <div
          style={style}
          className={classnames(styles.innerWrapper, {
            [styles.giveMore]:
              app.activeMode.type === "give me" && !workspace.isMouseDown,
            [styles.mouseDown]: workspace.clickedMoving,
          })}
          onMouseDown={handleDown}
          onMouseMove={handleMove}
          onMouseUp={handleUp}
          onDoubleClick={handleAddNewCloud}
          onWheelCapture={handleWheel}
        >
          <Space workspace={workspace} />
        </div>
      </Hammer>
      {workspace.inlineInput && <InlineInput workspace={workspace} />}
      <div className={styles.TopBar}>
        <WorkspaceModes workspace={workspace} />
        <WorkspaceActions workspace={workspace} />
      </div>
    </div>
  );
}

export default observer(Workspace);
