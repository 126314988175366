// @ts-nocheck

import uuid from "uuid";
import { observable, action } from "mobx";

import config from "../config";
import measureText from "../../utils/measureText";
import { makeObservable } from "mobx";

export default class Word {
  id = uuid.v1();
  label;
  position = {
    x: 0,
    y: 0,
  };
  isMain;
  textWidth;
  dimensions = {
    width: 0,
    height: 0,
  };
  state = {
    marked: false,
    softDeleted: false,
  };

  constructor({ label, isMain, uri }) {
    this.uri = uri;
    this.label = label;
    this.isMain = isMain;

    this.calculateTextWidth();

    makeObservable(this, {
      id: observable,
      label: observable,
      position: observable,
      isMain: observable,
      textWidth: observable,
      dimensions: observable,
      state: observable,
      toggleState: action,
      setPosition: action,
      setDimensions: action,
    });
  }

  calculateTextWidth() {
    const wordConfig = this.isMain ? config.mainWord : config.word;
    this.textWidth = Math.round(
      measureText({
        text: this.label,
        fontSize: wordConfig.font.size,
        fontFamily: wordConfig.font.family,
        fontWeight: wordConfig.font.weight,
      }) +
        wordConfig.padding.x * 2
    );
  }

  toggleState(key, value = {}) {
    this.state[key] =
      JSON.stringify(this.state[key]) === JSON.stringify(value) ? false : value;
  }

  setPosition({ x, y }) {
    this.position.x = x;
    this.position.y = y;
  }

  setDimensions({ width, height }) {
    this.dimensions.width = width;
    this.dimensions.height = height;
  }
}
