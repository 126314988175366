// @ts-nocheck
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import Hammer from "react-hammerjs";
import isjs from "is_js";

import styles from "./Word.module.scss";
import { useStores } from "../../../hooks/use-stores";

function Word({ word, cloud, workspace }) {
  const { config, app } = useStores();
  const [lastWord, setLastWord] = useState(false);

  useEffect(() => {
    setLastWord(word.label === ".");
  }, [word]);

  const wordConfig = word.isMain ? config.mainWord : config.word;
  const dy = word.isMain
    ? config.word.font.size - config.mainWord.font.size
    : 0;

  const handleInteraction = (e) => {
    if (
      e.button !== 0 &&
      !e.pointerType &&
      (!e.touches || e.touches.length !== 1)
    ) {
      return;
    }

    if (app.activeMode.type === "delete" || app.activeMode.type === "mark") {
      e.preventDefault();
      workspace.modeInteractionTriggered({
        word,
        cloud,
        mode: app.activeMode,
      });
      return;
    }

    if (word.isMain) {
      workspace.startDrag(cloud, word);
      return;
    }

    if (!app.activeMode.type) {
      workspace.revealCloudTriggered(word, cloud);
    }
  };

  const style = {
    fontWeight: wordConfig.font.weight,
    fontFamily: wordConfig.font.family,
    fontSize: wordConfig.font.size,
  };
  if (word.state.marked && !word.isMain) {
    style.fill = word.state.marked.color;
  } else if (word.isMain) {
    style.fill = cloud.state.marked.color;
  }

  const className = classnames(styles.wrapper, {
    [styles.isMain]:
      (!app.activeMode.type || app.activeMode.type === "give me") &&
      word.isMain,
    [styles.modeDelete]: app.activeMode.type === "delete",
    [styles.modeGiveMe]: app.activeMode.type === "give me",
    [styles.modeMark]: app.activeMode.type === "mark",
    [styles.softDeleted]: word.state.softDeleted && !cloud.state.softDeleted,
  });

  return (
    <Hammer
      onPress={
        (isjs.touchDevice() || isjs.tablet()) && !lastWord
          ? handleInteraction
          : () => {}
      }
    >
      <g
        onMouseDown={!lastWord ? handleInteraction : () => {}}
        className={className}
        style={{
          transform: `translate(${word.position.x}px, ${word.position.y}px)`,
          fontWeight: lastWord ? 600 : 400,
        }}
      >
        <rect
          x={0}
          y={0}
          className={styles.rect}
          width={word.dimensions.width}
          height={word.dimensions.height}
          fill="#fff"
        />
        <text
          style={style}
          className={styles.text}
          x={wordConfig.padding.x}
          y={0}
          dy={wordConfig.padding.y + wordConfig.font.size + dy - 2}
        >
          {word.label}
        </text>
      </g>
    </Hammer>
  );
}

export default observer(Word);
