// @ts-nocheck
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "../../../hooks/use-stores";
import Cloud from "../Cloud/Cloud";

function Space({ workspace }) {
  const { config } = useStores();

  const { clouds, revealCloud } = workspace;

  const _clouds = Array.from(clouds.slice(), (cloud) => (
    <Cloud
      key={cloud.id}
      isDragged={workspace.draggedCloud === cloud}
      workspace={workspace}
      cloud={cloud}
    />
  ));

  return (
    <svg
      style={{ opacity: clouds.length % 2 ? 1 : 0.99, display: "block" }}
      width={config.canvas.size}
      height={config.canvas.size}
    >
      <defs>
        <filter id="dropshadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
          <feOffset dx="2" dy="2" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.3" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      {_clouds}
      {revealCloud && (
        <Cloud isReveal cloud={revealCloud} workspace={workspace} />
      )}
    </svg>
  );
}

export default observer(Space);
