// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import HoverTooltip from '../HoverTooltip/HoverTooltip';

import styles from './WorkspaceActions.module.scss';
import { useStores } from '../../../hooks/use-stores';

const Tooltip = props => (
  <HoverTooltip
    placement="bottom"
    overlay={props.text}
    overlayClassName="rc-tooltip-information"
    mouseEnterDelay={0}
    mouseLeaveDelay={0}
    arrowContent={<div className="rc-tooltip-arrow-inner" />}
  >
    {props.children}
  </HoverTooltip>
);

function WorkspaceActionsLite() {
  const { tour } = useStores();

  const handleHelp = () => tour.makeVisible();

  return (
    <div className={classnames(styles.wrapper, styles.wrapperAlternative)}>
      <Tooltip text="Help">
        <i onClick={handleHelp} className="icon-l_question" />
      </Tooltip>
    </div>
  );
}

export default observer(WorkspaceActionsLite);
