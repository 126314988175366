// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import HoverTooltip from '../HoverTooltip/HoverTooltip';

import styles from './WorkspaceBar.module.scss';
import { useStores } from '../../../hooks/use-stores';

const types = {
  delete: 'Klik om het bord te selecteren voor verwijdering',
  mark: 'Verlaat deze modus om naar een ander bord te gaan',
  'give me': 'Verlaat deze modus om naar een ander bord te gaan',
};

function WorkspaceButton({ workspace }) {
  const { app } = useStores();

  const className = classnames(styles.WorkspaceButton, {
    [styles.isActive]: workspace.isActive,
    [styles.isSoftDeleted]: workspace.state.softDeleted,
  });

  const button = (
    <button
      className={className}
      style={{ color: app.workspaceBarColor }}
      onClick={() => app.setActiveWorkspaceId(workspace.id)}
      type="button"
    >
      {workspace.word}
    </button>
  );

  return app.activeMode.type === 'mark' ? (
    button
  ) : (
    <HoverTooltip
      placement="top"
      overlay={
        app.activeMode.type ? types[app.activeMode.type] : 'Klik om naar een ander canvas te gaan'
      }
      overlayClassName="rc-tooltip-information"
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
    >
      {button}
    </HoverTooltip>
  );
}

export default observer(WorkspaceButton);
