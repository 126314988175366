// @ts-nocheck
import React from "react";

import Workspace from "./Workspace/Workspace";
import WorkspaceBar from "./WorkspaceBar/WorkspaceBar";
// import Tour from './Tour/Tour';
import styles from "./App.module.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <div className={styles.wrapper}>
    {false && process.env.NODE_ENV !== "production"}
    {/* <Tour /> */}
    <ToastContainer />
    <Workspace />
    <WorkspaceBar />
  </div>
);

export default App;
