import React from "react";
import AutosizeInput from "react-input-autosize";

interface AutoSizeInputProps {
  onKeyDown?: any;
  onChange?: any;
}

class AutoSizeInput extends React.Component<AutoSizeInputProps> {
  state = {
    value: "",
  };

  handleKeyDown = (e) => {
    if (this.props.onKeyDown) {
      if (this.props.onKeyDown(e)) {
        this.setState({
          value: "",
        });
      }
    }
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    return (
      <AutosizeInput
        {...this.props}
        onChange={(e) => {
          if (this.props.onChange) {
            this.props.onChange(e);
          }
          // this.handleChange(e);
        }}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}

export default AutoSizeInput;
