import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';

import styles from './Cloud.module.scss';

function AddWord({ x, y, enabled, height, workspace, cloud }) {

  const { config } = useStores()

  if (!enabled || workspace.inlineInput) {
    return null;
  }

  const wordConfig = config.word;

  const handleAddWord = e => {
    e.preventDefault();
    e.stopPropagation();
    workspace.enableInlineInput('ADD_WORD', { cloud, position: {
      x: e.target.getBoundingClientRect().x,
      y: e.target.getBoundingClientRect().y
    } });
  };

  return (
    <g
      className={styles.addWordWrapper}
      style={{ cursor: 'pointer' }}
      transform={`translate(${x}, ${y})`}
      onMouseDown={handleAddWord}
    >
      <rect x={0} y={0} width={height} height={height} fill="#fff" />
      <rect
        className={styles.addWordBg}
        x={height / 4}
        y={height / 4}
        width={height / 2}
        height={height / 2}
        fill="#fff"
        strokeLinecap="round"
      />
      <text
        className={styles.addWordText}
        style={{
          fontWeight: wordConfig.font.weight,
          fontFamily: wordConfig.font.family,
          fontSize: wordConfig.font.size,
        }}
        x={wordConfig.padding.x + 1}
        y={0}
        dy={wordConfig.padding.y + wordConfig.font.size - 3}
      >
        +
      </text>
    </g>
  );
}

export default observer(AddWord);
