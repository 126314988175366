// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/web/App';

import './styles/screen.scss';

ReactDOM.render(
    <App />,
  document.getElementById('root'),
);