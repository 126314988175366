// @ts-nocheck

import React from 'react';
import Tooltip from 'rc-tooltip';
import IconButton from '../IconButton/IconButton';

export default class ExtrasButton extends React.Component {
  state = {
    isVisible: false,
  };

  render() {
    const { onAction } = this.props;

    const handleAction = type => () => {
      this.setState({ isVisible: false });
      onAction(type);
    };

    return (
      <Tooltip
        visible={this.state.isVisible}
        animation="zoom"
        trigger="click"
        placement="top"
        overlay={
          <ul>
            {process.env.NODE_ENV !== 'production' && (
              <li onClick={handleAction('export-marked')}>Export</li>
            )}
            <li onClick={handleAction('delete-unmarked')}>verwijder niet-gemarkeerd</li>
            <li onClick={handleAction('unmark-marked')}>herstel standaard markering</li>
            <li onClick={handleAction('extract-marked')}>nieuw canvas met gemarkeerde woorden</li>
          </ul>
        }
        overlayClassName="rc-tooltip-extreme-right"
      >
        <IconButton
          onClick={() => this.setState({ isVisible: !this.state.isVisible })}
          label="Extra"
          icon={<i className="icon-l_options" />}
        />
      </Tooltip>
    );
  }
}
