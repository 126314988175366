// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';

import WorkspaceCarousel from './WorkspaceCarousel';

import styles from './WorkspaceBar.module.scss';
import { useStores } from '../../../hooks/use-stores';

function WorkspaceBar() {

  const { app } = useStores();

  const style = {
    innerWrapper: {
      backgroundColor: app.workspaceBarColor,
    },
  };

  const inner = app.workspaces.length
    ? [
        <div className={styles.WorkspacesWrapper} key="workspaces">
          <WorkspaceCarousel
            activeWorkspace={app.activeWorkspace}
            workspaces={app.workspaces}
            app={app}
          />
        </div>,
        <div
          key="disclaimer"
          onClick={() => app.setDisclaimerOpen(true)}
          className={styles.disclaimer}
        >
          <p>Read disclaimer!</p>
          <p>© LUCA School of Arts</p>
        </div>,
      ]
    : [
        <div className="sitename" key="sitename">
          <a href="http://www.pondr.be">www.pondr.be</a>
        </div>,
        <div className="credits" key="credits">
          <a href="http://www.luca-arts.be/nieuws/lucida">© Luca School of Arts</a>
        </div>,
      ];

  return (
    <div className={styles.wrapper}>
      <div style={style.innerWrapper} className={styles.innerWrapper}>
        <div className={styles.LogoWrapper}>
          <img className={styles.logo} src="assets/images/pondr.png" alt="Pondr" />
        </div>
        {inner}
      </div>
    </div>
  );
}

export default observer(WorkspaceBar);
