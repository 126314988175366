// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';
import Tooltip from 'rc-tooltip';

import IconButton from '../IconButton/IconButton';
import { useStores } from '../../../hooks/use-stores';

function MarkButton({ onActivate, onConfirm, onAction, onCancel, isActive }) {
  const { config, app } = useStores();

  const icon = <i className="icon-mark" />;
  const options = app.activeMode.options;

  const toggleColorPicker = () =>
    app.updateModeOptions('mark', { isColorPickerVisible: !options.isColorPickerVisible });
  const toggleMoreActions = () =>
    app.updateModeOptions('mark', { isMoreActionsVisible: !options.isMoreActionsVisible });
  const hideMoreActions = () => app.updateModeOptions('mark', { isMoreActionsVisible: false });
  const hideColorPicker = () => app.updateModeOptions('mark', { isColorPickerVisible: false });
  const setColor = color => () => app.updateModeOptions('mark', { color });

  const handleAction = action => () => {
    hideMoreActions();
    onAction(action);
    onConfirm();
  };

  if (isActive) {
    return (
      <Tooltip
        visible={options.isColorPickerVisible}
        animation="zoom"
        trigger={["click"]}
        placement="top"
        overlay={
          <div>
            {config.modes.mark.defaultColors.map((color, i) => (
              <div
                onClick={setColor(color)}
                key={i}
                style={{
                  backgroundColor: color,
                  width: 25,
                  height: 25,
                  cursor: 'pointer',
                  marginBottom: 5,
                }}
              />
            ))}
          </div>
        }
      >
        <IconButton isActive={isActive} label="mark" onClick={toggleColorPicker} icon={icon} />
      </Tooltip>
    );
  }

  return <IconButton isActive={isActive} label="mark" onClick={onActivate} icon={icon} />;
}

export default observer(MarkButton);
