// @ts-nocheck
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import Hammer from "react-hammerjs";

import Word from "../Word/Word";
import styles from "./Cloud.module.scss";

import AddWord from "./AddWord";
import { useStores } from "../../../hooks/use-stores";
import { toast } from "react-toastify";

function Cloud({ cloud, workspace, isReveal, isDragged }) {
  const { config, app } = useStores();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (cloud.children.length === 1) {
      toast(
        <p style={{ margin: 0, padding: 0 }}>
          No associations found for this word.
          <br />
          You can add your own!
        </p>,
        {
          hideProgressBar: true,
          position: "top-right",
        }
      );
    }
  }, []);

  const handleChangeSize = (e, multiplier = 1) => {
    e.stopPropagation();
    if (workspace.moving) {
      return false;
    }
    if (
      isReveal ||
      (app.activeMode.type && app.activeMode.type !== "give me") ||
      workspace.revealCloud
    ) {
      return;
    }

    const direction = e.deltaY > 0 ? 1 : -1;
    window.clearTimeout(timer);
    workspace.setResizing(true);
    setTimer(
      window.setTimeout(() => {
        workspace.setResizing(false);
      }, 300)
    );
    workspace.changeCloudSize(cloud, direction * multiplier);
  };

  const handleActiveCloud = (active) => () => {
    if (isReveal || app.activeMode.type) {
      return;
    }

    workspace.activeCloudChanged(cloud, active);
  };

  return (
    <Hammer
      onPan={(e) => {
        handleChangeSize({ deltaY: e.angle > 0 ? -1 : 1 }, 5);
      }}
      options={{
        recognizers: {
          pan: { pointers: 2 },
        },
      }}
    >
      <g
        key={"g_" + cloud.id}
        className={classnames(styles.wrapper, {
          [styles.isCloudy]: workspace.revealCloud && !isReveal,
          [styles.isSoftDeleted]: cloud.state.softDeleted,
          [styles.hasBeenActive]: cloud.state.hasBeenActive,
          [styles.isDefault]: !isReveal && !isDragged,
          [styles.isReveal]: isReveal,
        })}
        filter={cloud.state.active ? "url(#dropshadow)" : ""}
        transform={`translate(${cloud.position.x - cloud.radius} ${
          cloud.position.y - cloud.radius
        })`}
        onMouseEnter={handleActiveCloud(true)}
        onMouseLeave={handleActiveCloud(false)}
        onWheel={handleChangeSize}
        onTouchStart={(e) => {
          handleActiveCloud(true)();
          e.preventDefault();
          (e as any).srcEvent.stopPropagation();
        }}
        onTouchEnd={(e) => {
          handleActiveCloud(false)();
          e.preventDefault();
          (e as any).srcEvent.stopPropagation();
        }}
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <g>
          {config.debug && (
            <circle
              fill="#fff"
              stroke="black"
              cx={cloud.radius}
              cy={cloud.radius}
              r={cloud.radius}
            />
          )}
          {cloud.children.map((word, i) => {
            return (
              <Word cloud={cloud} workspace={workspace} word={word} key={i} />
            );
          })}
          {!workspace.revealCloud && (
            <AddWord
              x={cloud.addWordPosition.x}
              y={cloud.addWordPosition.y}
              height={cloud.addWordPosition.height}
              enabled={cloud.state.active}
              config={config}
              cloud={cloud}
              workspace={workspace}
            />
          )}
        </g>
      </g>
    </Hammer>
  );
}

export default observer(Cloud);
