// @ts-nocheck

export default (count) => {
  const Row = () => ({
    items: [],
    $before: true,
    firstItem: null,
    insert(item) {
      if (this.items.length === 0) {
        this.firstItem = item;
      }
      if (this.$before) {
        this.items = [item].concat(this.items);
      } else {
        this.items.push(item);
      }
      this.$before = !this.$before;
    },
  });

  const table = {
    rows: [],
    getRow(index) {
      if (index >= this.rows.length) {
        return false;
      }
      return this.rows[index];
    },
    insert(item) {
      this.findSpot(item, 0);
    },
    insertIntoNewRow(item) {
      const row = Row();
      row.insert(item);
      this.rows.push(row);
    },
    findSpot(item, offset) {
      const row = this.getRow(offset);

      if (!row) {
        return this.insertIntoNewRow(item);
      }

      const t = Math.random() * 2 > 1 ? 1 : 2;
      const nextRow = this.getRow(offset + t);

      if (row.items.length === 1 && !nextRow) {
        return this.findSpot(item, offset + 1);
      }

      if (nextRow && row.items.length > nextRow.items.length) {
        return this.findSpot(item, offset + 1);
      }

      return row.insert(item);
    },
  };

  const output = [];

  for (let i = 0; i < count; i += 1) {
    table.insert(i);
    output.push([]);
  }

  let rows = [];
  let before = false;
  table.rows.forEach((row) => {
    if (before) {
      rows = [row].concat(rows);
    } else {
      rows.push(row);
    }
    before = !before;
  });

  const midIndex = rows.findIndex((r) => r === table.rows[0]);

  rows.forEach((r, i) => {
    const y = i - midIndex;
    const midWord = r.items.findIndex((w) => w === r.firstItem);
    r.items.forEach((w, j) => {
      const x = j - midWord;

      output[w] = [x, y];
    });
  });

  return output;
};
