// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import IconButton from '../IconButton/IconButton';
import styles from './Disclaimer.module.scss';
const Disclaimer = ({ app }) => {
  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: 'url(/assets/images/pattern-2.png)' }}
    >
      <div className={styles.action}>
        <IconButton
          onClick={() => app.setDisclaimerOpen(false)}
          icon="icon-not-ok"
          tooltipText="Ga terug"
        />
      </div>
      <div className={classnames(styles.container, 'container')}>
        <div className="row">
          <div className="col-md-6 col-md-offset-1">
            <h1>DISCLAIMER</h1>
            <p>
              <strong>
                Het onderstaande is van toepassing op de website die u nu bekijkt. Door de site te
                gebruiken stemt u in met deze disclaimer.
              </strong>
            </p>
          </div>
          <div className="col-md-2">
            <img src="/assets/images/ponzy.png" width={341 / 3} height={302 / 3} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-1">
            <p>
              LUCA School of Arts en haar medewerkers kunnen in geen geval aansprakelijk gesteld
              worden voor enige schade die het direct of indirect gevolg is van of in verband staat
              met het gebruik van de op deze website beschikbare informatie. Deze website dient als
              niet commerciële brainstormtool voor het gebruiken van associaties ten aanzien van
              uiteenlopende onderwerpen, die verzameld zijn en tot stand gekomen zijn vanuit een
              brede diversiteit aan opinies en standpunten. De associaties die tevoorschijn komen op
              de website, zijn op een willekeurige wijze gekoppeld aan de ingegeven begrippen. Ze
              kunnen niet toegeschreven worden aan LUCA School of Arts of haar medewerkers. Indien
              een bezoeker van de website van mening is dat welbepaalde associaties op de site
              onrechtmatig, kwetsend of aanstootgevend zijn, in welke zin dan ook, wordt die
              bezoeker verzocht om contact op te nemen met LUCA School of Arts.
            </p>

            <h3>Spelling</h3>
            <p>
              De weergegeven resultaten bevatten regelmatig dialectwoorden die niet als
              standaardnederlands beschouwd kunnen worden. Spellingsfouten tegen de Nederlandse taal
              worden zoveel mogelijk vermeden, maar mogelijk bevatten de resultaten alsnog verkeerde
              schrijfwijzen.
            </p>
            <h3>Privacy</h3>
            <p>
              Data die door de gebruiker ingevoerd wordt op Pondr wordt anoniem opgeslagen. Deze
              data wordt enkel gebruikt om de database verder uit te breiden en te optimaliseren. De
              identiteit van de gebruiker is niet gekend door Pondr.
            </p>
          </div>
          <div className="col-md-4">
            <h2>OVER PONDR</h2>
            <p>
              Pondr.space is het resultaat van het PWO-project ‘Lucida’ van{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:johny.vandevyver@luca-arts.be"
              >
                Johny Van de Vyver
              </a>{' '}
              en{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:ingwio.dhespeel@luca-arts.be"
              >
                Ingwio D’Hespeel
              </a>{' '}
              voor LUCA School of Arts. Meer info over het project vind je{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.luca-arts.be/nieuws/lucida"
              >
                hier
              </a>
              .
            </p>
            <hr />
            <h2>DATABASE</h2>
            <p>
              De data die pondr.space gebruikt is afkomstig uit de onafhankelijk studie{' '}
              <a target="_blank" rel="noopener noreferrer" href="http://www.smallworldofwords.com">
                ‘Small World of Words’
              </a>{' '}
              naar woordassociaties van <strong>Simon De Deyne</strong> en{' '}
              <strong>Gert Storms</strong> Experimentele Psychologie, KU Leuven.
            </p>
            <hr />
            <h2>THANKS TO</h2>
            <p>
              <a target="_blank" rel="noopener noreferrer" href="http://www.bitsoflove.be">
                Bits of Love
              </a>
              <br />
              <strong>Dieter de Paepe</strong> iMinds/Imec
              <br />
              <strong>Dr. Simon De Deyne</strong>, KU Leuven
              <br />
              <strong>Dr. Tom De Smet</strong>, UAntwerpen
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
