// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import HoverTooltip from '../HoverTooltip/HoverTooltip';
import measureText from '../../../utils/measureText';
import WorkspaceButton from './WorkspaceButton';
import styles from './WorkspaceCarousel.module.scss';

const MAX_WIDTH_PERCENT = 0.5;
const constrain = (min, max) => n => Math.min(max, Math.max(min, n));

class WorkspaceCarousel extends React.Component {
  static defaultProps = {
    controlWidth: 38,
    itemMargin: 4,
    startMargin: 4,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      availableWidth: 0,
      outerWidth: 0,
      innerWidth: 0,
      offset: -props.controlWidth - props.startMargin,
      index: 0,
      reachedEnd: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let index = this.state.index;
    if (this.props.activeWorkspace !== nextProps.activeWorkspace) {
      const activeIndex = nextProps.workspaces.indexOf(nextProps.activeWorkspace);
      if (activeIndex > -1) {
        index = this.getMaxIndex(activeIndex, nextProps);
      }
    }

    const result = this.calculate(nextProps.workspaces, index, this.state.availableWidth);
    this.setState({
      index,
      ...result,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getMaxIndex(index, props) {
    for (let i = 0; i <= index; i += 1) {
      const result = this.calculate(props.workspaces, i, this.state.availableWidth);
      if (result.reachedEnd) {
        return i;
      }
    }

    return index;
  }

  calculate = (workspaces, index, availableWidth) => {
    const output = {
      innerWidth: -1,
      outerWidth: -1,
      offset: 0,
      reachedEnd: false,
    };

    output.outerWidth = workspaces.reduce((total, current, currentIndex) => {
      if (currentIndex === index) {
        output.offset = total;
      }

      const n = Math.ceil(
        total +
          10 +
          30 +
          measureText({ text: current.word, fontSize: 15, fontFamily: 'Rubik', fontWeight: 500 }),
      );

      if (currentIndex >= index && n - output.offset > availableWidth && output.innerWidth === -1) {
        output.innerWidth = total - output.offset;
      }

      return n;
    }, 0);

    if (output.innerWidth === -1) {
      output.innerWidth = output.outerWidth - output.offset;
      output.reachedEnd = true;
    }

    return output;
  };

  handleResize = () => {
    const availableWidth = Math.min(
      this.wrapper.parentElement.getBoundingClientRect().width,
      window.innerWidth * MAX_WIDTH_PERCENT,
    );
    const result = this.calculate(this.props.workspaces, this.state.index, availableWidth);

    this.setState({
      availableWidth,
      ...result,
    });
  };

  handleIndexChanged = inc => () => {
    if (inc > 0 && this.state.reachedEnd) {
      return;
    }

    const index = constrain(0, this.props.workspaces.length - 1)(this.state.index + inc);
    const result = this.calculate(this.props.workspaces, index, this.state.availableWidth);

    this.setState({
      index,
      ...result,
    });
  };

  handleNewWorkspace = () => {
    this.props.app.setActiveWorkspaceId(null);
  };

  renderItems() {
    return this.props.workspaces.map(workspace => (
      <WorkspaceButton workspace={workspace} key={workspace.id} />
    ));
  }

  render() {
    return (
      <div
        ref={c => {
          this.wrapper = c;
        }}
        className={styles.Carousel}
      >
        <div
          className={classnames(styles.Previous, {
            [styles.disabled]: this.state.index === 0,
          })}
          onClick={this.handleIndexChanged(-1)}
        >
          <i className="icon-arrow-left" />
        </div>

        <div
          className={styles.Items}
          style={{ width: this.state.innerWidth }}
          onWheel={e => this.handleIndexChanged(e.deltaY > 0 ? 1 : -1)()}
        >
          <div style={{ width: this.state.outerWidth, marginLeft: -this.state.offset }}>
            {this.renderItems()}
          </div>
        </div>

        {app.activeWorkspace && (
          <HoverTooltip
            placement="top"
            animation="zoom"
            overlay="Klik om een bord toe te voegen"
            overlayClassName="rc-tooltip-information"
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
          >
            <button className={styles.AddButton} onClick={this.handleNewWorkspace}>
              +
            </button>
          </HoverTooltip>
        )}

        <div
          className={classnames(styles.Next, {
            [styles.disabled]: this.state.reachedEnd,
          })}
          onClick={this.handleIndexChanged(1)}
        >
          <i className="icon-arrow-right" />
        </div>
      </div>
    );
  }
}

export default observer( WorkspaceCarousel);
