// @ts-nocheck
import createTable from "./createSpiralTable";

import config from "../stores/config";

const tables = {};
const spiralTile = (n, cid) => {
  if (!tables[cid]) {
    tables[cid] = createTable(config.clouds.wordLimit);
  }

  return tables[cid][n];
};

export default spiralTile;

// export default (n) => {
//     const r = Math.floor(Math.sqrt(n));
//
//     const hr = Math.round(r / 2);
//     const eu = Math.pow(-1, r);
//     const euo = Math.pow(-1, r + 1);
//     const ar = Math.abs((r * (r + 1)) - n);
//     const rr = ((r * (r + 1)) - n);
//
//     const a = (hr * euo) + ((euo * (rr - ar)) / 2);
//     const b = (hr * eu) + ((euo * (rr + ar)) / 2);
//
//     return [a, b];
// };
