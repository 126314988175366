// @ts-nocheck
import { observable, computed, action, makeObservable } from "mobx";
import Workspace from "./models/workspace";

import * as BigScreen from "bigscreen";

import config from "./config";
import tour from "./tour";

class AppStore {
  constructor() {
    makeObservable(this, {
      workspaces: observable,
      activeWorkspaceId: observable,
      disclaimerOpen: observable,
      isFullScreen: observable,
      activeMode: observable,
      workspaceBarColor: computed,
      activeWorkspace: computed,
      setActiveWorkspaceId: action,
      addNewWorkspace: action,
      toggleMode: action,
      updateModeOptions: action,
      actionTriggered: action,
      setDisclaimerOpen: action,
      toggleFullScreen: action,
    });
  }
  workspaces = [];
  activeWorkspaceId = null;
  disclaimerOpen = false;

  isFullScreen = false;

  activeMode = {
    type: null,
    options: {},
  };

  get workspaceBarColor() {
    if (this.activeMode.type === "delete") {
      return "#000";
    }

    if (this.activeMode.type === "give me") {
      return "#F3A8AB";
    }

    if (this.activeMode.type === "mark") {
      return this.activeMode.options.color;
    }

    return config.primaryBackgroundColor;
  }

  get activeWorkspace() {
    if (!this.activeWorkspaceId) {
      return null;
    }

    return this.getWorkspaceById(this.activeWorkspaceId);
  }

  setActiveWorkspaceId(id) {
    if (id === null) {
      tour.show("newWorkspace");
    } else if (tour.activeStep !== "firstWorkspace") {
      tour.show("firstWorkspace");
      tour.close();
    }

    this.newWorkspaceValue = null;
    const newWorkspace = this.getWorkspaceById(id);
    if (this.activeMode.type) {
      if (this.activeMode.type === "delete") {
        newWorkspace.toggleState("softDeleted");
      }
      return;
    }

    if (id === this.activeWorkspaceId) {
      return;
    }

    const oldWorkspace = this.getWorkspaceById(this.activeWorkspaceId);
    if (oldWorkspace) {
      oldWorkspace.setActive(false);
    }

    if (newWorkspace) {
      newWorkspace.setActive(true);
    }

    this.activeWorkspaceId = id;
  }

  setNewWorkspaceValue(v) {
    this.newWorkspaceValue = v;
  }

  addNewWorkspace(word, data) {
    if (this.workspaces.length === 0) {
      tour.show("firstWorkspace");
    }

    const workspace = new Workspace(word, data);
    this.workspaces.push(workspace);
    this.setActiveWorkspaceId(workspace.id);
  }

  toggleMode(mode, options = {}) {
    if (this.activeMode.type !== mode) {
      tour.show(`mode-${mode}`);
    } else {
      tour.show("firstWorkspace");
      tour.close();
    }

    this.activeWorkspace.toggleCloudSuggestionList(false);
    this.activeWorkspace.disableInlineInput();
    let unsetActiveWorkspace;
    if (this.activeMode.type === "delete" && mode === "delete") {
      if (options.cancelled) {
        this.workspaces.forEach((w) => {
          if (w.state.softDeleted) {
            w.toggleState("softDeleted");
          }
        });
        this.activeWorkspace.restoreState();
      } else if (options.confirmed) {
        this.activeWorkspace.performDelete();

        if (this.activeWorkspace.state.softDeleted) {
          unsetActiveWorkspace = true;
        }

        this.workspaces = this.workspaces.filter((w) => !w.state.softDeleted);
      }
    } else if (options.cancelled) {
      this.activeWorkspace.restoreState();
    }

    if (this.activeMode.type === null) {
      this.activeWorkspace.saveState();
    }

    this.activeMode.type = this.activeMode.type === mode ? null : mode;
    this.activeMode.options = options;

    if (unsetActiveWorkspace) {
      this.setActiveWorkspaceId(null);
    }
  }

  updateModeOptions(mode, options) {
    if (this.activeMode.type === mode) {
      this.activeMode.options = { ...this.activeMode.options, ...options };
    }
  }

  getWorkspaceById(id) {
    return this.workspaces.find((workspace) => workspace.id === id);
  }

  actionTriggered(act) {
    this.activeWorkspace.toggleCloudSuggestionList(false);
    switch (act) {
      case "delete-unmarked":
        this.activeWorkspace.deleteUnmarked();
        break;
      case "extract-marked":
        this.activeWorkspace.extractMarked();
        break;
      case "unmark-marked":
        this.activeWorkspace.unmarkMarked();
        break;
      case "export-marked":
        this.activeWorkspace.exportMarked();
        break;
      default:
        return;
    }
  }

  toggleFullScreen(bigScreen = true) {
    this.isFullScreen = !this.isFullScreen;

    if (BigScreen.enabled && bigScreen) {
      BigScreen.toggle();
    }
  }

  setDisclaimerOpen(bool) {
    this.disclaimerOpen = bool;
  }
}

const store = new AppStore();

window.app = store;

export default store;
