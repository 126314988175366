// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import IconButton from '../IconButton/IconButton';
import MarkButton from './MarkButton';
import DeleteButton from './DeleteButton';
import GiveMeButton from './GiveMeButton';
import ExtrasButton from './ExtrasButton';
import Actions from './Actions';

import styles from './WorkspaceModes.module.scss';
import { useStores } from '../../../hooks/use-stores';

const types = {
  delete: 'Verwijder',
  mark: 'Markeer',
  'give me': 'Gimme',
};

function WorkspaceModes() {
  const { config, app } = useStores();

  const style = {
    innerWrapper: {
      backgroundColor: app.workspaceBarColor,
    },
  };

  const handleMode = (mode, options) => () => app.toggleMode(mode, options);

  return (
    <div
      className={classnames(styles.ModeToggleWrapper, { [styles.hidden]: !app.activeWorkspace })}
      key="modes"
    >
      <IconButton
        isActive={!app.activeMode.type || app.activeMode.type === 'play'}
        label="play"
        onClick={handleMode('play')}
        icon={<i className="icon-hand" />}
      />
      <DeleteButton
        isActive={app.activeMode.type === 'delete'}
        onActivate={handleMode('delete')}
        onConfirm={handleMode('delete', {
          confirmed: true,
        })}
        onCancel={handleMode('delete', {
          cancelled: true,
        })}
      />
      <MarkButton
        isActive={app.activeMode.type === 'mark'}
        onActivate={handleMode('mark', {
          color: config.modes.mark.defaultColors[0],
          isColorPickerVisible: true,
          isMoreActionsVisible: false,
        })}
        onConfirm={handleMode('mark', {
          confirmed: true,
        })}
        onCancel={handleMode('mark', {
          cancelled: true,
        })}
        onAction={action => app.actionTriggered(action)}
      />
      <GiveMeButton
        isActive={app.activeMode.type === 'give me'}
        onActivate={handleMode('give me')}
        onConfirm={handleMode('give me', {
          confirmed: true,
        })}
        onCancel={handleMode('give me', {
          cancelled: true,
        })}
      />
      <ExtrasButton onAction={action => app.actionTriggered(action)} />

      <Actions activeMode={app.activeMode.type} onToggleMode={handleMode} />
    </div>
  );
}

export default observer(WorkspaceModes);
