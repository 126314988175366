// @ts-nocheck
const a = document.createElement("canvas");
const b = a.getContext("2d");

export default function measureText({
  text,
  fontSize,
  fontFamily,
  fontWeight = "",
}) {
  b.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  return Math.ceil(b.measureText(text).width);
}
