// @ts-nocheck
import React from 'react';
import IconButton from '../IconButton/IconButton';

const DeleteButton = ({ onActivate, isActive }) => {
  return (
    <IconButton
      isActive={isActive}
      label="delete"
      onClick={onActivate}
      icon={<i className="icon-erase" />}
    />
  );
};

export default DeleteButton;