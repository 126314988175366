// @ts-nocheck
import React, { useState } from "react";
import { observer } from "mobx-react";
import AutoSizeInput from "../AutoSizeInput/AutoSizeInput";
import $ from "jquery";

import WorkspaceActionsLite from "../WorkspaceActions/WorkspaceActionsLite";

import styles from "./Intro.module.scss";
import { useStores } from "../../../hooks/use-stores";
import * as api from "../../../services/api";
import AutoComplete from "../AutoComplete/AutoComplete";

function Intro() {
  const { app } = useStores();
  const [predictions, setPredictions] = useState([]);
  const [value, setValue] = useState("");
  const handleCreate = () => {
    if (app.newWorkspaceValue) {
      app.addNewWorkspace(app.newWorkspaceValue);
    }

    const input = $("input");
    if (input.length) {
      input.blur();
    }
  };

  const handleArrow = (direction) => {
    if (predictions.length <= 0) {
      return false;
    }

    let selectedIndex;
    predictions.forEach((el, i) => {
      if (el.selected) selectedIndex = i;
    });

    const reset = () => {
      let reset = [];
      predictions.forEach((p) => {
        p.selected = false;
        reset.push(p);
      });
      return reset;
    };
    if (direction === "up") {
      if (isNaN(selectedIndex)) {
        setPredictions((predictions) => {
          const rs = reset();
          rs[rs.length - 1].selected = true;
          return rs;
        });
      } else {
        setPredictions((predictions) => {
          const rs = reset();
          if (selectedIndex !== 0) {
            rs[selectedIndex - 1].selected = true;
          }
          return rs;
        });
      }
    } else {
      if (isNaN(selectedIndex)) {
        setPredictions((predictions) => {
          const rs = reset();
          rs[0].selected = true;
          return rs;
        });
      } else {
        setPredictions((predictions) => {
          const rs = reset();
          if (selectedIndex !== rs.length - 1) {
            rs[selectedIndex + 1].selected = true;
          }
          return rs;
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 40) {
      e.preventDefault();
      handleArrow("down");
    } else if (e.keyCode === 38) {
      e.preventDefault();
      handleArrow("up");
    }
    const value = e.target.value;
    if (value && e.keyCode === 13) {
      e.target.blur();
      const selected = predictions.filter((p) => p.selected)[0];
      if (selected) {
        app.addNewWorkspace(selected.word);
        return;
      }
      app.addNewWorkspace(value);
    }
  };

  function handlePredictionClick(word) {
    app.addNewWorkspace(word);
  }

  const handleKeyUp = async (e) => {
    const value = e.target.value;
    app.setNewWorkspaceValue(value);
  };

  const handleChange = async (e) => {
    setValue(e.target.value);
    if (e.target.value.length > 1) {
      const res = await api.predict(e.target.value, 1, "swow");
      const predictions = res.data.items;
      let uniquePredictions = [];
      predictions.forEach((p) => {
        if (!uniquePredictions.filter((_p) => p === _p.word).length) {
          uniquePredictions.push({ word: p, selected: false });
        }
      });
      setPredictions(uniquePredictions);
    } else {
      setPredictions([]);
    }
  };

  const renderMessage = () => {
    if (app.workspaces.length > 0) {
      return (
        <div>
          <h1 className="other-font">Yes! Let's create a new board!</h1>
        </div>
      );
    }

    return (
      <div>
        <h1>Ponzy is excited to get started!</h1>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <WorkspaceActionsLite />
      <div className={styles.innerWrapper}>
        <img
          className={styles.ponzyImg}
          src="assets/images/ponzy.png"
          alt="Ponzy"
        />
        {renderMessage()}
        <div className={styles.searchInput}>
          <AutoSizeInput
            autoFocus
            className={styles.input}
            inputStyle={{ fontSize: 18 }}
            placeholder={
              app.workspaces.length > 0 ? "Let's chew on it! " : "Start hier! "
            }
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            value={value}
          />
          <span className={styles.searchIcon} onClick={handleCreate}>
            <i className="material-icons">arrow_forward</i>
          </span>
          {predictions.length ? (
            <AutoComplete
              predictions={predictions}
              handleClick={handlePredictionClick}
            />
          ) : null}
        </div>
        {app.workspaces.length === 0 && (
          <div className={styles.feedback}>
            <p>
              Geef ons{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/forms/SLK0M9CIM4jE5h3n2"
              >
                feedback
              </a>
            </p>
            <p>
              of{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:johny.vandevyver@luca-arts.be"
              >
                contacteer ons
              </a>
            </p>
            <hr />
            <p
              style={{ cursor: "pointer" }}
              onClick={() => app.setDisclaimerOpen(true)}
            >
              lees de <a>disclaimer</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Intro);
