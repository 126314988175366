// @ts-nocheck

import uuid from "uuid";
import { observable, action, computed } from "mobx";
import throttle from "throttle-debounce/throttle";
import d3 from "d3";

import * as api from "../../services/api";
import config from "../config";
import Cloud from "./cloud";

import app from "../app";
import $ from "jquery";
import { makeObservable } from "mobx";
import { runInAction } from "mobx";

export default class Workspace {
  id = uuid.v1();

  state = {
    softDeleted: false,
  };

  isCloudSuggestionListVisible = false;

  // States
  isActive = false;
  isLoading = false;
  isMouseDown = false;

  // Definition
  word;

  // Children
  clouds = [];
  lockWorkspace = false;
  revealCloud = null;
  draggedCloud = null;

  // Pan things
  mouseDownPosition = { x: 0, y: 0 };
  lastPan = { x: 0, y: 0 };
  pan = { x: 0, y: 0 };
  scale = 1;

  moving = false;
  resizing = false;
  clickedMoving = false;

  inlineInput = null;
  activeCloud = null;

  constructor(word, data) {
    this.word = word;
    this.loadClouds(data);

    this.syncCloudPositions = throttle(100, () => {
      this.clouds.forEach((cloud) => {
        cloud.syncPosition();
      });
    });

    makeObservable(this, {
      id: observable,
      state: observable,
      isCloudSuggestionListVisible: observable,
      isActive: observable,
      isLoading: observable,
      isMouseDown: observable,
      word: observable,
      clouds: observable,
      revealCloud: observable,
      draggedCloud: observable,
      mouseDownPosition: observable,
      lastPan: observable,
      pan: observable,
      moving: observable,
      resizing: observable,
      scale: observable,
      inlineInput: observable,
      addToClouds: action,
      updateRevealCloud: action,
      update: action,
      loadClouds: action,
      setActive: action,
      setMoving: action,
      setResizing: action,
      toggleState: action,
      center: action,
      cleanup: action,
      receivedMouseDown: action,
      receivedMouseUp: action,
      receivedMouseMove: action,
      startDrag: action,
      stopDrag: action,
      revealCloudTriggered: action,
      changeCloudSize: action,
      addCloudAtPosition: action,
      activeCloudChanged: action,
      modeInteractionTriggered: action,
      performDelete: action,
      removeSoftDeletes: action,
      deleteUnmarked: action,
      extractMarked: action,
      unmarkMarked: action,
      exportMarked: action,
      enableInlineInput: action,
      disableInlineInput: action,
      suggestions: computed,
      toggleCloudSuggestionList: action,
      restoreState: action,
      saveState: action,
      changePan: action,
      changeScale: action,
      clickedMoving: observable,
      setClickedMoving: action
    });
  }

  update() {
    this.syncCloudPositions();
  }

  loadClouds(data) {
    this.isLoading = true;

    api.reset(this.id);

    if (!data) {
      api.fetch(this.word, 1, "swow", this.id).then((words) => {
        const cloud = new Cloud(this.word, words, 12);
        cloud.workspace = this;
        cloud.position.x = 15000;
        cloud.position.y = 15000;
        this.addToClouds(cloud);

        // Init the pan
        this.center();

        this.isLoading = false;
      });
    } else {
      const promises = data.map((d) => {
        const word = d.label || d.word;
        const color = d.state.marked.color;

        return new Promise((resolve) => {
          api.fetch(word, 1, "swow", this.id).then((words) => {
            const cloud = new Cloud(word, words, 12, color);
            cloud.workspace = this;
            this.clouds.push(cloud);

            resolve();
          });
        });
      });

      Promise.all(promises).then(() => {
        this.isLoading = false;

        // Init the pan
        this.center();

        this.cleanup(true);
      });
    }
  }

  setActive(state) {
    this.toggleCloudSuggestionList(false);
    this.isActive = state;
  }

  setMoving(state) {
    this.moving = state;
  }

  setResizing(state: boolean){
    this.resizing = state;
  }

  setClickedMoving(state){
    this.clickedMoving = state;
  }

  toggleState(key, value = {}) {
    this.state[key] =
      JSON.stringify(this.state[key]) === JSON.stringify(value) ? false : value;
  }

  center() {
    this.pan.x = -(config.canvas.size / 2) + window.innerWidth / 2;
    this.pan.y = -(config.canvas.size / 2) + (window.innerHeight - 56) / 2;
  }

  cleanup(usePrevious) {
    const clouds = this.clouds.map((c) => ({
      value: c.radius,
      id: c.id,
      word: c.word,
      cluster: usePrevious
        ? c.previousColor
        : c.state.marked
        ? c.state.marked.color
        : null,
    }));

    d3.layout
      .pack()
      .sort(null)
      .radius((d) => d)
      .children((d) => d.values)
      .size([30000, 30000])
      .padding(25)
      .nodes({
        values: d3
          .nest()
          .key((d) => d.cluster)
          .entries(clouds),
      });

    clouds.forEach((cloud, i) => {
      this.clouds[i].setPosition(cloud.x, cloud.y);
    });
  }

  /**
   * Pan things
   */

  receivedMouseDown(position) {
    this.isMouseDown = true;
    this.toggleCloudSuggestionList(false);
    this.lastPan.x = this.pan.x;
    this.lastPan.y = this.pan.y;
    this.mouseDownPosition.x = position.x;
    this.mouseDownPosition.y = position.y;
  }

  receivedMouseUp() {
    this.isMouseDown = false;
    this.setClickedMoving(false);
    if (this.draggedCloud) {
      if (this.revealCloud) {
        if (!this.activeCloud) {
          const { originalPosition, position } = this.revealCloud;
          const distance =
            Math.abs(originalPosition.x - position.x) +
            Math.abs(originalPosition.y - position.y);

          if (distance > 100) {
            this.revealCloudParent.removeWord(this.revealCloud.word);

            this.addCloudAtPosition(
              this.revealCloud.word,
              {
                x: this.revealCloud.position.x,
                y: this.revealCloud.position.y,
              },
              true,
              { uri: this.revealCloud.uri }
            );
          }
        } else if (
          this.activeCloud !== this.revealCloud &&
          this.activeCloud.word !== this.revealCloud.word &&
          this.activeCloud.word !== this.revealCloudParent.word
        ) {
          const word = `${this.activeCloud.word} + ${this.revealCloud.word}`;

          api.fetch(word, 1, "swow", this.id).then((words) => {
            this.activeCloud.changeWord(word, words);
          });
        }

        this.revealCloud = null;
        this.revealCloudParent.setState("cloudy", false);
        this.revealCloudParent = null;
      }
    }

    this.stopDrag();
  }

  handlePan(position) {
    if (!this.draggedCloud && !this.lockWorkspace) {
      const x = this.pan.x - position.x;
      const y = this.pan.y - position.y;
      this.changePan({ x, y });
    }
  }

  handleZoom(scale) {
    if (scale >= 2 || scale <= 0.5) {
      return false;
    }
    this.changeScale(scale);
  }

  receivedMouseMove(position) {
    this.setClickedMoving(true);
    if (this.isMouseDown && !this.draggedCloud && !this.lockWorkspace) {
      this.pan.x = this.lastPan.x - (this.mouseDownPosition.x - position.x);
      this.pan.y = this.lastPan.y - (this.mouseDownPosition.y - position.y);
    }

    if (this.draggedCloud) {
      this.draggedCloud.position.x =
        this.draggedCloud.originalPosition.x -
        (this.mouseDownPosition.x - position.x) / this.scale;
      this.draggedCloud.position.y =
        this.draggedCloud.originalPosition.y -
        (this.mouseDownPosition.y - position.y) / this.scale;

      if (this.revealCloud === this.draggedCloud) {
        const dx = Math.abs(
          this.revealCloud.originalPosition.x - this.revealCloud.position.x
        );
        const dy = Math.abs(
          this.revealCloud.originalPosition.y - this.revealCloud.position.y
        );
        const d = dx + dy;

        if (d > 50 && this.revealCloud.visibleWordCount > 1) {
          this.revealCloud.setSize(1);
        }
      }
    }
  }

  startDrag(cloud, clickedWord, parentCloud) {
    this.draggedCloud = cloud;
    this.isMouseDown = true;

    let x, y;
    if (parentCloud) {
      // Correct the position
      const mainWord = cloud.children.find((c) => c.isMain);
      x =
        parentCloud.position.x -
        parentCloud.radius +
        clickedWord.position.x +
        clickedWord.dimensions.width / 2 +
        cloud.radius -
        mainWord.position.x -
        mainWord.dimensions.width / 2;
      y =
        parentCloud.position.y -
        parentCloud.radius +
        clickedWord.position.y +
        clickedWord.dimensions.height / 2 +
        cloud.radius -
        mainWord.position.y -
        mainWord.dimensions.height / 2;

      cloud.position.x = x;
      cloud.position.y = y;
    } else {
      x = cloud.position.x;
      y = cloud.position.y;
    }

    cloud.originalPosition = { x, y };
  }

  stopDrag() {
    this.draggedCloud = null;
  }

  updateRevealCloud(item) {
    this.revealCloud = item;
  }

  /**
   * Reveal things
   */

  revealCloudTriggered(clickedWord, cloud) {
    this.toggleCloudSuggestionList(false);
    this.revealCloudParent = cloud;
    this.lockWorkspace = true;
    this.isMouseDown = true;
    cloud.setState("cloudy", true);

    api
      .fetch(clickedWord.uri || clickedWord.label, 1, "swow", this.id, false)
      .then((words) => {
        if (!this.isMouseDown) {
          return;
        }

        this.updateRevealCloud(
          new Cloud(clickedWord.label, words, 15, null, clickedWord.uri)
        );
        this.startDrag(this.revealCloud, clickedWord, cloud);
        this.lockWorkspace = false;
      });
  }

  changeCloudSize(cloud, by) {
    if (this.isMouseDown) {
      return;
    }

    cloud.increaseSize(by);
  }

  addToClouds = (cloud: Cloud) => {
    runInAction(() => {
      this.clouds.push(cloud);
    });
  };

  changePan(pan: { x: number; y: number }) {
    runInAction(() => {
      this.pan.x = pan.x;
      this.pan.y = pan.y;
    });
  }

  changeScale(scale: number) {
    runInAction(() => {
      this.scale = scale;
    });
  }

  addCloudAtPosition(word, position, setActive = false, options = {}) {
    return api.fetch(options.uri || word, 1, "swow", this.id).then((words) => {
      const cloud = new Cloud(word, words, 15);
      cloud.workspace = this;
      cloud.position.x = position.x;
      cloud.position.y = position.y;
      this.addToClouds(cloud);

      if (setActive) {
        this.activeCloudChanged(cloud);
        cloud.setState("hasBeenActive", true);
      }

      if (options.setAsDragged) {
        this.startDrag(cloud);
      }
    });
  }

  activeCloudChanged(cloud, active) {
    if (this.activeCloud === cloud && !active) {
      this.activeCloud = null;
      cloud.setState("active", false);
    } else {
      if (this.activeCloud) {
        this.activeCloud.setState("active", false);
      }

      if (active) {
        this.activeCloud = cloud;
        cloud.setState("active", true);
        cloud.setState("hasBeenActive", true);
      }
    }
  }

  modeInteractionTriggered({ mode, word, cloud }) {
    this.toggleCloudSuggestionList(false);
    if (mode.type === "delete") {
      if (word.isMain) {
        cloud.toggleState("softDeleted");
      } else {
        word.toggleState("softDeleted");
      }
    }
    if (mode.type === "mark") {
      if (word.isMain) {
        cloud.toggleState("marked", mode.options);
      } else {
        word.toggleState("marked", mode.options);
      }
    }
  }

  performDelete() {
    this.clouds = this.clouds.filter((cloud) => {
      if (cloud.state.softDeleted) {
        cloud.words.forEach((word) => {
          api.unseeWord(this.id, word.label);
        });
        return false;
      }

      return true;
    });

    this.clouds.forEach((cloud) => {
      const words = cloud.words.filter((word) => {
        if (!word.state.softDeleted) {
          return true;
        }
        api.unseeWord(this.id, word.label);

        return false;
      });

      if (words.length !== cloud.words.length) {
        cloud.setWords(words);
      }
    });
  }

  removeSoftDeletes() {
    this.clouds.forEach((c) => {
      if (c.state.softDeleted) {
        c.toggleState("softDeleted");
      }

      c.words.forEach((w) => {
        if (w.state.softDeleted) {
          w.toggleState("softDeleted");
        }
      });
    });
  }

  deleteUnmarked() {
    this.clouds.forEach((cloud) => {
      const words = cloud.words.filter((word) => {
        if (
          cloud.children.indexOf(word) === -1 ||
          word.isMain ||
          word.state.marked
        ) {
          return true;
        }

        api.unseeWord(this.id, word.label);

        return false;
      });
      if (words.length !== cloud.words.length) {
        cloud.setWords(words);
      }
    });
  }

  extractMarked() {
    const marked = this.exportMarked();

    app.addNewWorkspace("Extracted", [...marked.clouds, ...marked.words]);
  }

  unmarkMarked() {
    this.clouds.forEach((cloud) => {
      if (cloud.state.marked) {
        cloud.toggleState("marked");
        return;
      }

      cloud.words.forEach((word) => {
        if (word.state.marked) {
          word.toggleState("marked");
        }
      });
    });
  }

  exportMarked() {
    const exported = {
      clouds: [],
      words: [],
    };

    this.clouds.forEach((cloud) => {
      if (cloud.state.marked) {
        exported.clouds.push(cloud);
        return;
      }

      cloud.words.forEach((word) => {
        if (word.state.marked) {
          exported.words.push(word);
        }
      });
    });

    return exported;
  }

  enableInlineInput(type, options) {
    this.inlineInput = { type, options };
  }

  disableInlineInput() {
    const input = $("input");
    if (input.length) {
      input.blur();
    }
    this.inlineInput = null;
  }

  get suggestions() {
    if (!this.clouds.length) {
      return [];
    }

    const seen = api.getSeen(this.id);
    return [null, null, null, null].map(() => {
      const index = Math.floor(Math.random() * seen.length);

      return seen[index].replace("http://www.smallworldofwords.com/words/", "");
    });
  }

  toggleCloudSuggestionList(v) {
    this.isCloudSuggestionListVisible =
      typeof v === "undefined" ? !this.isCloudSuggestionListVisible : v;
  }

  restoreState() {
    this.savedState.clouds.forEach((savedCloud) => {
      const cloud = this.clouds.find((c) => c.id === savedCloud.id);
      cloud.state = savedCloud.state;

      savedCloud.words.forEach((savedWord) => {
        const word = cloud.words.find((w) => w.id === savedWord.id);
        word.state = savedWord.state;
      });
    });
  }

  saveState() {
    this.savedState = {
      clouds: this.clouds.map((cloud) => {
        return {
          id: cloud.id,
          state: JSON.parse(JSON.stringify(cloud.state)),
          position: {
            x: cloud.position.x,
            y: cloud.position.y,
          },
          words: cloud.words.map((word) => {
            return {
              id: word.id,
              state: JSON.parse(JSON.stringify(word.state)),
            };
          }),
        };
      }),
    };
  }
}
