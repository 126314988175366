// @ts-nocheck

import React from 'react';
import IconButton from '../IconButton/IconButton';

const GiveMeButton = ({ onActivate, isActive }) => {
  return (
    <IconButton
      isActive={isActive}
      label="gimme"
      onClick={onActivate}
      icon={<i className="icon-add-cloud" />}
    />
  );
};

export default GiveMeButton;