// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';

import HoverTooltip from '../HoverTooltip/HoverTooltip';
import IconButton from '../IconButton/IconButton';

import styles from './WorkspaceActions.module.scss';
import { useStores } from '../../../hooks/use-stores';

import * as BigScreen from 'bigscreen';
import swal from 'sweetalert';

const Tooltip = props => (
  <HoverTooltip
    placement="bottom"
    overlay={props.text}
    overlayClassName="rc-tooltip-information"
    mouseEnterDelay={0}
    mouseLeaveDelay={0}
    arrowContent={<div className="rc-tooltip-arrow-inner" />}
  >
    {props.children}
  </HoverTooltip>
);

function WorkspaceActions({ workspace }) {
  const { tour, app } = useStores();

  const handleCleanup = () => {
    swal(
      {
        title: 'Ben je zeker?',
        text: 'Deze actie zal het canvas herstructureren',
        showCancelButton: true,
        confirmButtonColor: '#39A284',
        confirmButtonText: 'Ja, alles opschonen',
        cancelButtonText: 'Neen, doe niets',
        closeOnConfirm: true,
        imageUrl: 'assets/images/broom.png',
      }
    ).then(() => {
      workspace.cleanup();
      workspace.center();
    });
  };

  const handleCenter = () => {
    workspace.center();
  };

  const handleFullscreen = () => {
    app.toggleFullScreen();
  };

  const handleHelp = () => tour.makeVisible();

  return (
    <div className={styles.wrapper}>
      <IconButton
        icon={<i className="icon-l_crosshair" />}
        onClick={handleCenter}
        label={
          <span>
            Center
            <br />
            canvas
          </span>
        }
      />
      <IconButton
        icon={<i className="icon-l_broom" />}
        onClick={handleCleanup}
        label={
          <span>
            Clean up
            <br />
            canvas
          </span>
        }
      />
      {BigScreen.enabled && (
        <IconButton
          icon={
            <i className={!app.isFullScreen ? 'icon-l_fullscreen' : 'icon-l_exit_fullscreen'} />
          }
          onClick={handleFullscreen}
          label={
            <span>
              Full
              <br />
              screen
            </span>
          }
        />
      )}
      <IconButton icon={<i className="icon-l_question" />} onClick={handleHelp} label="help" />
    </div>
  );
}

export default observer(WorkspaceActions);
