// @ts-nocheck

import axios from "axios";

// production
const BASE_URL = process.env.REACT_APP_BASE_URL
  ? "http://" + process.env.REACT_APP_BASE_URL
  : "";

// production
// const BASE_URL =  "http://api.pondr.space"

// local
// const BASE_URL = 'http://localhost:3000';

const cache = {};
const seen = {};

const seenWordFilter = (workspaceId, addToSeen = false) => (word) => {
  const label = word.uri ? word.uri : word.label;
  const isNotSeen = label !== "x" && seen[workspaceId].indexOf(label) === -1;
  if (isNotSeen && addToSeen) {
    seen[workspaceId].push(label);
  }

  return isNotSeen;
};

export function reset(workspaceId) {
  seen[workspaceId] = [];
}

export function unseeWord(workspaceId, label) {
  seen[workspaceId] = seen[workspaceId].filter((w) => w !== label);
}

export function getSeen(workspaceId) {
  return seen[workspaceId];
}

export function fetch(word, levels = 1, db, workspaceId, addToSeen = true) {
  const realWord = word.indexOf("http") === -1 ? word.trim() : word;
  if (cache[realWord] && levels <= 1) {
    return Promise.resolve(JSON.parse(JSON.stringify(cache[realWord])));
  }

  const _seenFilter = seenWordFilter(workspaceId, addToSeen);
  if (addToSeen) {
    seen[workspaceId].push(realWord);
  }

  // Check if combined
  if (realWord.indexOf("+") > -1) {
    return new Promise((resolve, reject) => {
      Promise.all(
        realWord.split("+").map((w) => this.fetch(w, 1, db, workspaceId))
      )
        .then((data) => {
          const pw = {};
          data.forEach((words) => {
            words.forEach((w) => {
              if (pw[w.label]) {
                pw[w.label].weight += +w.weight + 500;
              } else {
                pw[w.label] = w;
                pw[w.label].weight = +w.weight;
              }
            });
          });

          const sorted = Object.keys(pw)
            .sort((a, b) => {
              return pw[a].weight - pw[b].weight;
            })
            .map((k) => pw[k]);

          resolve(sorted);
        })
        .catch(reject);
    });
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/${db}?q=${realWord}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (
          response.data.source === "disambiguate" &&
          response.data.items.length
        ) {
          return this.fetch(
            response.data.items[0].uri,
            1,
            db,
            workspaceId
          ).then((words) => {
            resolve(words);
          });
        }

        let words;

        if (response.data.items) {
          words = response.data.items;
        } else if (response.data.links) {
          words = response.data.links;
        } else if (response.data && response.data.map) {
          words = [];
          response.data.forEach((source) => {
            words = words.concat(source.items);
          });
        }

        words.forEach((w) => {
          if (!w.label) {
            w.label = w.title;
            w.uri = w.link;
          }
        });

        words = words.filter(_seenFilter);

        if (levels > 1) {
          const promises = [];
          words = words.slice(0, 100).map((w) => {
            const promise = this.fetch(
              w.uri || w.link,
              levels - 1,
              db,
              workspaceId
            );
            promises.push(promise);
            promise.then((children) => {
              w.children = children.filter(_seenFilter);
            });
            return w;
          });
          Promise.all(promises).then(() => resolve(words));
        } else {
          cache[realWord] = words;
          resolve(words);
        }
      })
      .catch(reject);
  });
}

let cancelToken;
export function predict(word, levels = 1, db) {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  cancelToken = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/${db}?p=${word}`, {
        cancelToken: cancelToken.token,
        headers: {
          Accept: "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      });
  });
}

export function newAssociation(data) {
  axios.post(`${BASE_URL}/associations/new`, { ...data });
}
