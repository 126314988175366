// @ts-nocheck
import React, { useState } from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import Tooltip from "rc-tooltip";

import AutoSizeInput from "../AutoSizeInput/AutoSizeInput";
import * as api from "../../../services/api";
import styles from "./InlineInput.module.scss";
import AutoComplete from "../AutoComplete/AutoComplete";

const getPosition = (type, options, workspace) => {
  if (type === "ADD_WORD") {
    return {
      x:
        options.cloud.position.x -
        options.cloud.radius / workspace.scale +
        options.cloud.addWordPosition.x +
        workspace.pan.x +
        5,
      y:
        options.cloud.position.y / workspace.scale -
        options.cloud.radius / workspace.scale +
        options.cloud.addWordPosition.y +
        workspace.pan.y +
        5,
    };
  }

  if (type === "ADD_CLOUD" || type === "ADD_CLOUD_LIST") {
    return {
      x: workspace.pan.x + options.position.x,
      y: workspace.pan.y + (options.position.y - 10),
    };
  }

  return null;
};

function InlineInput({ workspace }) {
  const type = workspace.inlineInput.type;
  const options = workspace.inlineInput.options;

  const position = getPosition(type, options, workspace);

  const [value, setValue] = useState("");
  const [predictions, setPredictions] = useState([]);

  const style = {
    position: "fixed",
    top: position.y,
    left: position.x,
  };

  if (type === "ADD_CLOUD_LIST") {
    return (
      <Tooltip
        visible
        animation="zoom"
        trigger="click"
        placement="top"
        overlay={
          <ul className={styles.SuggestionList}>
            {workspace.suggestions.map((suggestion, i) => (
              <li
                key={`${suggestion}-${i}`}
                style={{ cursor: "pointer" }}
                onMouseUp={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  workspace.addCloudAtPosition(suggestion, options.position);
                  workspace.disableInlineInput();
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        }
      >
        <span style={style} className={styles.Cloud}>
          <i className="icon-add-cloud" />
        </span>
      </Tooltip>
    );
  }

  const handleArrow = (direction) => {
    if (predictions.length <= 0) {
      return false;
    }

    let selectedIndex;
    predictions.forEach((el, i) => {
      if (el.selected) selectedIndex = i;
    });

    const reset = () => {
      let reset = [];
      predictions.forEach((p) => {
        p.selected = false;
        reset.push(p);
      });
      return reset;
    };
    if (direction === "up") {
      if (isNaN(selectedIndex)) {
        setPredictions((predictions) => {
          const rs = reset();
          rs[rs.length - 1].selected = true;
          return rs;
        });
      } else {
        setPredictions((predictions) => {
          const rs = reset();
          if (selectedIndex !== 0) {
            rs[selectedIndex - 1].selected = true;
          }
          return rs;
        });
      }
    } else {
      if (isNaN(selectedIndex)) {
        setPredictions((predictions) => {
          const rs = reset();
          rs[0].selected = true;
          return rs;
        });
      } else {
        setPredictions((predictions) => {
          const rs = reset();
          if (selectedIndex !== rs.length - 1) {
            rs[selectedIndex + 1].selected = true;
          }
          return rs;
        });
      }
    }
  };

  const handlePredictionClick = (word) => {
    workspace.addCloudAtPosition(word, options.position);
    workspace.disableInlineInput();
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (e.keyCode === 40) {
      e.preventDefault();
      handleArrow("down");
    } else if (e.keyCode === 38) {
      e.preventDefault();
      handleArrow("up");
    }
    if (value && value.length > 1 && e.keyCode === 13) {
      if (type === "ADD_WORD") {
        const { word } = options.cloud;
        options.cloud.addWord(value);
        api.newAssociation({ word, value });
        return true;
      }
      if (type === "ADD_CLOUD") {
        setValue("");
        const selected = predictions.filter((p) => p.selected)[0];
        if (selected) {
          workspace.addCloudAtPosition(selected.word, options.position);
        } else {
          workspace.addCloudAtPosition(value, options.position);
        }
        workspace.disableInlineInput();
        e.target.blur();
        return true;
      }
    }

    if (e.keyCode === 27) {
      workspace.disableInlineInput();
      e.target.blur();
    }

    return false;
  };

  const inputClassName = classnames(styles.input, {
    [styles.addCloud]: type === "ADD_CLOUD",
    [styles.addWord]: type === "ADD_WORD",
  });

  const inputWidth = type === "ADD_CLOUD" ? 150 : 90;

  const handleChange = async (e) => {
    setValue(e.target.value);
    if (e.target.value.length > 1) {
      const res = await api.predict(e.target.value, 1, "swow");
      const predictions = res.data.items;
      let uniquePredictions = [];
      predictions.forEach((p) => {
        if (!uniquePredictions.filter((_p) => p === _p.word).length) {
          uniquePredictions.push({ word: p, selected: false });
        }
      });
      setPredictions(uniquePredictions);
    } else {
      setPredictions([]);
    }
  };

  return (
    <div style={style}>
      <AutoSizeInput
        type="text"
        autoFocus
        minWidth={inputWidth}
        value={value}
        onChange={handleChange}
        placeholder={
          type === "ADD_CLOUD" ? "Wolk toevoegen" : "Woord toevoegen"
        }
        onBlur={() => false && workspace.disableInlineInput()}
        onKeyDown={handleKeyDown}
        className={inputClassName}
      />
      {type === "ADD_CLOUD" && predictions.length > 0 && (
        <AutoComplete
          handleClick={handlePredictionClick}
          predictions={predictions}
          className={styles.predictions}
        />
      )}
    </div>
  );
}

export default observer(InlineInput);
