import { observable, action, makeObservable } from "mobx";

class TourStore {
  constructor() {
    makeObservable(this, {
      activeStep: observable,
      visible: observable,
      state: observable,
      makeVisible: action,
      show: action,
      close: action,
    });
  }
  activeStep = "intro";
  visible = true;
  state = {
    intro: true,
    newWorkspace: false,
    firstWorkspace: false,
    "mode-mark": false,
    "mode-delete": false,
    "mode-give me": false,
  };

  makeVisible() {
    this.visible = true;
  }

  show(step) {
    if (!this.state[step]) {
      this.visible = true;
    }
    this.state[step] = true;
    this.activeStep = step;
  }

  close() {
    this.visible = false;
  }
}

export default new TourStore();
